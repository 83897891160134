/* ServiceRequestButtonBanner.module.css */
.banner {
    position: relative;
    width: 100%;
    overflow: hidden;
    object-fit: contain;
  }
.bannerHome {
    position: relative;
    width: 100%;
    overflow: hidden;
    object-fit: contain;
    height: 323px;
    


  }
.bannerImageHome {
    position: relative;
    width: 100%;
    overflow: hidden;
    opacity: .2;
  }
  
  .bannerImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.2; /* Set opacity */
  }
  .bannerTextPrimary {
    position: relative;
    z-index: 2; /* Ensure text is above the image */
    color: white; /* Adjust color to stand out against the background */
    margin: 0;
  }
  .bannerTextSecondary {
    position: relative;
    z-index: 2; /* Ensure text is above the image */
    color: white; /* Adjust color to stand out against the background */
    margin: 0;
  }
  
  .link {
    position: relative;
    z-index: 2; /* Ensure link is above the image */
    background-color: rgba(0, 0, 0, 0.5); /* Optional: make the link stand out */
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
  }