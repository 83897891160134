/* Chatbox.css */

.sticky-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #0070f3;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .chatbox {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 300px;
    height: 400px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
  }
  
  .chatbox-header {
    padding: 10px;
    background-color: #0070f3;
    color: white;
    text-align: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .chatbox-content {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
  }
  
  .chatbox-footer {
    padding: 10px;
    border-top: 1px solid #ddd;
  }
  
  .chatbox-footer input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .user-info {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 300px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 20px;
  }
  
  .user-info form div {
    margin-bottom: 15px;
  }
  