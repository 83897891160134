.preface{
    height: max-content;
    align-self: center;
    text-align: justify;

}

.loadingPage{
    background-color: rgba($color: #000000, $alpha: .1);
    height: 100vh;
    position: absolute;
    width: 100%;
    top:0;
    display: flex;
    align-items: center;
	left:0;
    justify-content: center;
    font-size: 62.5%;
    webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
    &::after, ::before { -webkit-box-sizing: border-box; box-sizing: border-box; }
}

/* Loader 6 */
.loader-6 {
	height: 128px;
	width: 128px;
}
.loader-6 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 128px;
	width: 128px;
}
.loader-6 span::before,
.loader-6 span::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 128px;
	width: 128px;
	border: 2px solid #000;
	border-radius: 50%;
	opacity: 0;
	-webkit-animation: loader-6-1 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite;
	        animation: loader-6-1 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) infinite;
}
@-webkit-keyframes loader-6-1 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1.5); opacity: 0; }
}
@keyframes loader-6-1 {
	0%   { transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
	100% { transform: translate3d(0, 0, 0) scale(1.5); opacity: 0; }
}
.loader-6 span::after {
	-webkit-animation: loader-6-2 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) .25s infinite;
	        animation: loader-6-2 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) .25s infinite;
}
@-webkit-keyframes loader-6-2 {
	0%   { -webkit-transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
	100% { -webkit-transform: translate3d(0, 0, 0) scale(1); opacity: 0; }
}
@keyframes loader-6-2 {
	0%   { transform: translate3d(0, 0, 0) scale(0); opacity: 1; }
	100% { transform: translate3d(0, 0, 0) scale(1); opacity: 0; }
}

/* Layout */
.center {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
}
.loader-list {
	display: -webkit-flex;
	display:         flex;
	-webkit-flex-flow: row wrap;
	        flex-flow: row wrap;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-align-content: center;
	        align-content: center;
	position: relative;
	width: 100%;
	height: 100%;
	clear: both;
}
.loader-list li {
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	position: relative;
	display: block;
	width: calc(100% / 3);
	height: calc(100vh / 4);
}