/* Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset and global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

body {
  background-color: #fff;
  color: #333;
  line-height: 1.6;

  
}
footer{
  width: 100%;
}
.main{
  min-height: 80vh;
}

 /* Hide scrollbar for Chrome, Safari and Opera */
 .no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
a {
  color: #007aff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Sidebar styles */
.fixed-inset-0 {
  inset: 0;
}

.transition-transform {
  transition: transform 0.3s ease;
}

.translate-x-full {
  transform: translateX(100%);
}

.translate-x-0 {
  transform: translateX(0);
}

.bg-black {
  background-color: black;
}

.bg-opacity-50 {
  opacity: 0.5;
}
.inner{
  max-width: 1300px;
  width: 100%;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  a{
    color: #505050;
    text-decoration: none !important;

  }

}
.container-self {
  -ms-overflow-style: none !important;  /* Internet Explorer 10+ */
  scrollbar-width: none !important;  /* Firefox */
}
.container-self::-webkit-scrollbar { 
  display: none !important;  /* Safari and Chrome */
}

.pageContainer{
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important;

}
.onepage{
  width: 100%;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1300px;
}

.link-button{
  text-decoration: none !important;
}
.content{
  overflow: auto;
}

.content ul {
  list-style-type: inherit !important;
  margin-left: 20px;
}

.content ol {
  list-style-type: inherit !important;
  margin-left: 20px;
}

.content li {
  margin-bottom: 5px;
  list-style-type: inherit !important;

}
.content table,td,tbody {
  border: thin solid grey !important;
  padding: inherit !important;
}

.content tr,td {
  padding: 0 .5rem !important;
}

.content-loaded {
  opacity: 1;
}


.rectangleImg{
  aspect-ratio:1.31 / 1 !important ;
  object-fit: cover;
}
.squareImg{
  aspect-ratio: 1 / 1 !important;
  object-fit: cover;

}

.rectangleImgO{
  aspect-ratio:1.31 / 1 !important;
  object-fit: contain;

}

.overflowing-box::after {
  content: "";
  display: block;
  position: absolute;
  top: -0px;
  right: 20px;
  width: 100%;
  height: 100%;
  border-top: 1px solid gainsboro;
  border-bottom: 1px solid gainsboro;
}
.overflowing-box::before {
  content: "";
  display: block;
  position: absolute;
  top: -5%;
  right: 1px;
  width: 100%;
  height: 110%;
  border-left: 1px solid gainsboro;
  
}

